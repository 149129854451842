// src/pages/Contacts.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const Contacts = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userloading, setUserloading] = useState(false)

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get('https://organic-seeds-backend.onrender.com/api/contacts');

        console.log(response)
        setContacts(response.data);
        setLoading(false);
      } catch (error) {
        toast.error('Error fetching contacts');
        setLoading(false);
      }
    };

    fetchContacts();
  }, []);

  const handleDeleteContact = async (id) => {
    try {
      await axios.delete(`https://organic-seeds-backend.onrender.com/api/contacts/${id}`);
      toast.success('Contact deleted successfully');
      // Refresh the contact list
      setContacts(contacts.filter(contact => contact._id !== id));
    } catch (error) {
      toast.error('Error deleting contact');
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Contact List</h1>

      {loading ? (
        <div className="flex justify-center">
          <div className="loader"></div> {/* Replace this with your Loader component */}
        </div>
      ) : (
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border">First Name</th>
              <th className="py-2 px-4 border">Last Name</th>
              <th className="py-2 px-4 border">Email</th>
              <th className="py-2 px-4 border">Message</th>
              <th className="py-2 px-4 border">Date</th>
              <th className="py-2 px-4 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map(contact => (
              <tr key={contact._id}>
                <td className="py-2 px-4 border">{contact.firstname}</td>
                <td className="py-2 px-4 border">{contact.lastname}</td>
                <td className="py-2 px-4 border">{contact.email}</td>
                <td className="py-2 px-4 border">{contact.message}</td>
                <td className="py-2 px-4 border">{new Date(contact.createdAt).toLocaleDateString()}</td>
                <td className="py-2 px-4 border">
                  <button
                    onClick={() => handleDeleteContact(contact._id)}
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Contacts;
