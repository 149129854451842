// src/pages/Dashboard.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import MainContent from '../components/MainContent';
import Header from '../components/Header';

const Dashboard = ({children}) => {
  return (
    <div className="dashboard-container flex">
      <Sidebar />
      <div className="flex-1">
        <Header />
        {children}
      </div>
    </div>
  );
};

export default Dashboard;
