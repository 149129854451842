// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Signup from './pages/Signup';
import Login from './pages/Login';
import PublicRoute from './components/PublicRoute';
import Dashboard from './pages/Dashboard';
import ProjectTable from './pages/Crud';
import Blog from './pages/Blog';
import Product from './pages/Product';
import ProtectedRoute from './components/PrivateRoute';
import Contacts from './pages/Contacts';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Public Routes */}
          <Route path="/signup" element={<PublicRoute><Signup /></PublicRoute>} />
          <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
          <Route path="/" element={<PublicRoute><Login /></PublicRoute>} />
          
          {/* Protected Routes */}
          <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/products" element={<ProtectedRoute><Dashboard><Product /></Dashboard></ProtectedRoute>} />
          <Route path="/blogs" element={<ProtectedRoute><Dashboard><Blog /></Dashboard></ProtectedRoute>} />
          <Route path="/contacts" element={<ProtectedRoute><Dashboard><Contacts  /></Dashboard></ProtectedRoute>} />
        </Routes>
      </div>
      <ToastContainer />
    </Router>
  );
};

export default App;
