// src/pages/Product.js
import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../components/Loader'; // Ensure you have a Loader component
import Modal from '../components/Modal'; // Ensure you have a Modal component

const Product = () => {
  const [products, setProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://organic-seeds-backend.onrender.com/api/products');
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleAddProduct = async (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('content', values.content);
    formData.append('price', values.price);
    if (values.image) {
      formData.append('image', values.image);
    }

    try {
      await axios.post('https://organic-seeds-backend.onrender.com/api/products', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Product added successfully');
      setIsModalOpen(false);
      // Refresh the product list
      const response = await axios.get('https://organic-seeds-backend.onrender.com/api/products');
      setProducts(response.data);
    } catch (error) {
      toast.error('Error adding product');
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      await axios.delete(`https://organic-seeds-backend.onrender.com/api/products/${id}`);
      toast.success('Product deleted successfully');
      // Refresh the product list
      const response = await axios.get('https://organic-seeds-backend.onrender.com/api/products');
      setProducts(response.data);
    } catch (error) {
      toast.error('Error deleting product');
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold">Product Page</h1>
        <button 
          onClick={() => setIsModalOpen(true)}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          Add Product
        </button>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div className="space-y-4 grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3  gap-4">
          {products.map(product => (
             <div class="bg-white border rounded-2xl p-5 cursor-pointer hover:-translate-y-2 transition-all relative">
             <div onClick={()=>{handleDeleteProduct(product?._id)}}
               class="bg-gray-100 w-10 h-10 flex items-center justify-center rounded-full cursor-pointer absolute top-4 right-4">
               <svg xmlns="http://www.w3.org/2000/svg" width="16px" class="fill-gray-800 inline-block" viewBox="0 0 64 64">
                 <path
                   d="M45.5 4A18.53 18.53 0 0 0 32 9.86 18.5 18.5 0 0 0 0 22.5C0 40.92 29.71 59 31 59.71a2 2 0 0 0 2.06 0C34.29 59 64 40.92 64 22.5A18.52 18.52 0 0 0 45.5 4ZM32 55.64C26.83 52.34 4 36.92 4 22.5a14.5 14.5 0 0 1 26.36-8.33 2 2 0 0 0 3.27 0A14.5 14.5 0 0 1 60 22.5c0 14.41-22.83 29.83-28 33.14Z"
                   data-original="#000000"></path>
               </svg>
             </div>
 
             <div class="w-5/6 h-[210px] overflow-hidden mx-auto aspect-w-16 aspect-h-8 md:mb-2 mb-4">
               <img src={product?.imageUrl} alt="Product 1"
                 class="h-full w-full object-contain" />
             </div>
 
             <div>
               <h3 class="text-lg font-extrabold text-gray-800">{product?.name}</h3>
               <p class="text-gray-600 text-sm mt-2">{product?.content}</p>
               <h4 class="text-lg text-gray-800 font-bold mt-4">${product?.price}</h4>
             </div>
           </div>
          ))}
        </div>
    //     <div class="font-[sans-serif] bg-gray-100">
    //     <div class="p-4 mx-auto lg:max-w-7xl sm:max-w-full">
    //       <h2 class="text-4xl font-extrabold text-gray-800 mb-12">Premium Sneakers</h2>
  
    //       <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 max-xl:gap-4 gap-6">
           
    //       </div>
    //     </div>
    //   </div>
      )}

      {/* Modal for Adding Product */}
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <Formik
            initialValues={{ name: '', content: '', price: '', image: null }}
            onSubmit={handleAddProduct}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Name</label>
                  <Field
                    name="name"
                    type="text"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Content</label>
                  <Field
                    name="content"
                    as="textarea"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Price</label>
                  <Field
                    name="price"
                    type="number"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Image</label>
                  <input
                    name="image"
                    type="file"
                    accept="image/*"
                    className="mt-1 block w-full"
                    onChange={(event) => setFieldValue('image', event.currentTarget.files[0])}
                  />
                </div>
                <div className="flex justify-end gap-2">
                  <button
                    type="button"
                    onClick={() => setIsModalOpen(false)}
                    className="px-4 py-2 bg-gray-500 text-white rounded"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded"
                  >
                    Add Product
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </div>
  );
};

export default Product;
