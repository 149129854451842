// src/components/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <aside className="sidebar w-64 bg-gray-800 text-white min-h-screen">
      <div className="p-4">
        <h2 className="text-xl font-semibold">Dashboard</h2>
        <nav className="mt-6">
          <ul>
            <li>
              <Link to="/dashboard" className="block py-2 px-4 hover:bg-gray-700">Dashboard</Link>
            </li>
            <li>
              <Link to="/projects" className="block py-2 px-4 hover:bg-gray-700">Projects</Link>
            </li>
            <li>
              <Link to="/products" className="block py-2 px-4 hover:bg-gray-700">Products</Link>
            </li>
            <li>
              <Link to="/blogs" className="block py-2 px-4 hover:bg-gray-700">Blogs</Link>
            </li>
            <Link to="/contacts" activeClassName="bg-gray-700" className="block py-2 px-4 hover:bg-gray-700">
            Contacts
          </Link>
            {/* Add more links here */}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
